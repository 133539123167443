import { useMemo } from 'react';
import { Tooltip } from 'react-tippy';
import PageWrapper from './PageWrapper';

const BIRTH = new Date('2007-01-11T6:17:00Z');
const YEAR_MILLIS = 473354280000;

const Home = () => {
  const age = useMemo(() => Math.floor((Date.now() - BIRTH.getTime()) / YEAR_MILLIS), []);

  return (
    <PageWrapper forceReadableWidth>
      <h1>Introduction</h1>
      <p>
        Hi, I'm Proochles, a public relation manager and aspiring psychologist.
      </p>
      <p>
        I enjoy creating, managing, and analyzing high-quality, dependable services. 
        I'm currently working on a bunch of projects; {' '}
        <a href={'/resume'}>see my resume for details!</a>
      </p>

      <p>
      I enjoy playing video games like League Of Legends, Minecraft, and other titles while I'm not working on my several projects. 
      I enjoy assisting decision-makers such as founders, product managers, community managers, etc.
      </p>

      <h3>My Future</h3>

      <p>
      I want to study to be a psychologist. I've always been interested in human growth and development. 
      I enjoy doing research on adolescent development and how particular life choices affect your character, morals, etc.
      </p>

      <h3>Closing Statment</h3>
      <p>
      I'm Clifford Robinson, and I've been using the internet to browse for at least five years now in an effort to get experience in a variety of fields, including psychology, community management, music production, and communication.
      If you have any questions, you can{' '}
        <a href={'/contact'}>visit my contact page</a>.
      </p>
    </PageWrapper>
  );
};

export default Home;
