import PageWrapper from './PageWrapper';
import Co from '../components/Co';
import styled from 'styled-components';
import BlossomAppIcon from '../assets/images/blossom-icon.png';
import UmmiIcon from '../assets/images/ummi-icon.gif';
import KnightusIcon from '../assets/images/leo-icon.png';
import MCIcon from '../assets/images/mc-icon.png';
import TicketIcon from '../assets/images/ticket-icon.png';
import PalIcon from '../assets/images/PalLogoUmmi.png';
import BlackCatIcon from '../assets/images/BlackCatIcon.png';
import DEVIcon from '../assets/images/DevIcon.png';
import NerdsIcon from '../assets/images/NerdIcon.png';
import Repo from '../components/Repo';
import { Helmet } from 'react-helmet';

const Resume = () => {
  return (
    <PageWrapper>
      <Helmet>
        <title>Resume</title>
      </Helmet>
      <h1>Current Work</h1>
      <CoWrapper>
        <Co
          url="https://paldomains.org"
          name="PalDomains"
          iconReference={PalIcon}
          tagline="Free Subdomain Service"
          role={'CEO'}
          description={'Creating business strategies and proposing implementation methods, and run day-to-day operations at PalDomains'}
          current
        />
        <Co
          url="https://dev.to"
          name="DEV"
          iconReference={DEVIcon}
          tagline="Developer Forum"
          role={'Moderator'}
          description={'Upholds community values by taking necessary action to ensure that the community guidelines are being followed.'}
          current
        />
        <Co
          url="https://littleummi.com"
          name="Little Ummi"
          iconReference={UmmiIcon}
          tagline="Commissionable Artist"
          role={'Public Relation Manager'}
          description={'Oversee communication relations for platforms such as; Discord, Tiktok, and Twitter.'}
          current
        />
        <Co
          url="https://twitch.tv/leooknightus"
          name="Leooknightus"
          iconReference={KnightusIcon}
          tagline="Twitch Streamer"
          role={'Manager'}
          description={'Planning and monitoring current and future projects, related to Twitch and Discord.'}
          current
        />
        <Co
          url="https://ticketsbot.net"
          name="Tickets"
          iconReference={TicketIcon}
          tagline="Discord Bot"
          role={'Support Representative'}
          description={'Responsible for client and customer inquiries and queries.'}
          current
        />
        <Co
          url="https://blossomcraft.org"
          name="BlossomCraft"
          iconReference={BlossomAppIcon}
          tagline="Minecraft Server"
          role={'Head Manager'}
          description={'Accomplishes department objectives by managing staff; planning and evaluating department activities.'}
          current
        />
      </CoWrapper>
    </PageWrapper>
  );
};

const CoWrapper = styled.div`
  display: grid;
  width: 100%;
  gap: 2rem 2rem;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export default Resume;
