import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageWrapper from './PageWrapper';

const Contact = () => (
  <PageWrapper forceReadableWidth>
    <Helmet>
      <title>Contact</title>
    </Helmet>
    <h1>Contact</h1>
    <p>
      Thank you for visting my site. This site allows others to see information on who I am.
      If you have any questions, I'm most responsive through Twitter, you can{' '}
      <a href={'https://twitter.com/messages/compose?recipient_id=1241805099875401729'}>click here</a> to DM
      me on Twitter.

      If it's an emergency, you can email{' '}
      <a href="mailto:proochlesmc@gmail.com">proochlesmc@gmail.com</a>.
    </p>
    <h2>Other</h2>
    <ul>
    <li>
        <a href={'https://paypal.me/proochles'}>PayPal</a>
    </li>
    <li>
        <a href={'https://twitch.com/proochles'}>Twitch</a>
    </li>
    <li>
        <a href={'https://twitter.com/proochles'}>Twitter</a>
    </li>
    <li>
        <a href={'https://github.com/Phineas/phineas.io'}>Source Code</a>
    </li>
    </ul>
  </PageWrapper>
);

export default Contact;
