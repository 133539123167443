import PageWrapper from './PageWrapper';
import Ref from '../components/Ref';
import styled from 'styled-components';
import BlossomIcon from '../assets/images/blossom-icon.png';
import KnightusIcon from '../assets/images/leo-icon.png';
import Repo from '../components/Repo';
import { Helmet } from 'react-helmet';

const References = () => {
  return (
    <PageWrapper>
      <Helmet>
        <title>References</title>
      </Helmet>
      <h1>References</h1>
      <RefWrapper>
      <Ref
          url="https://twitch.tv/leooknightus"
          name="LeooKnightus"
          iconReference={KnightusIcon}
          tagline="Twitch Streamer"
          letter={'Working with Clifford has been an absolute pleasure. I have known Clifford since November 26th, 2022, and has worked for me as my Discord, and Twitch manager. Clifford has been very efficient, and communicative when it comes to the tasks at hand. He provides me with solutions to help with stream quality, and engagement, as well as improving my Discord server. Clifford is very knowledgeable, and passionate when he decides to partake in a new project. You can really feel his energy in the room. He’s a high performer, and it has been a great pleasure to have him on my team. We wouldn’t be here without him.'}
          current
        />
      <Ref
          url="https://blossomcraft.org"
          name="BlossomCraft"
          iconReference={BlossomIcon}
          tagline="Minecraft Community"
          letter={'As an owner of BlossomCraft, one of the largest Minecraft networks, I am in constant need for members of my community who have the abilities to assist with the overseeing of the network, which I believe Clifford has demonstrated. Clifford who has obtained his position by volunteering for over a year, working his way up to a head manager of the network. His responsibilities include the managing and the moderation of an active Discord (social media platform) community of 7000+ active members, overseeing a staff team of 25+ members, as well as working on other additional projects. Some of the projects he\'s worked on includes the setup of multiple Discord Bots, websites, content moderation systems, and most notably an active ticketing system. I can vouch for his ability to complete his tasks diligently, timely, and always ensuring his work displays his full capabilities without compromises. Additionally, I believe his character and work displays initiative, and the ability to handle his emotions and personal feelings out of work under stressful situations. It is a pleasure to work alongside Clifford and I hope to continue interacting with them.'}
          current
        />
      </RefWrapper>
    </PageWrapper>
  );
};

const RefWrapper = styled.div`
  display: grid;
  width: 100%;
  gap: 2rem 2rem;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export default References;